/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@font-face {
  font-family: 'OpenSans';
  src: local('Open Sans Light'), local('Open Sans-Light'),
  url('./assets/fonts/open-sans/OpenSans-Light.ttf') format('ttf'),
  url('./assets/fonts/open-sans/OpenSans-Light.ttf') format('ttf');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'OpenSans';
  src: local('Open Sans Bold Italic'), local('OpenSans-BoldItalic'),
  url('./assets/fonts/open-sans/OpenSans-BoldItalic.ttf') format('ttf'),
  url('./assets/fonts/open-sans/OpenSans-BoldItalic.ttf') format('ttf');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'OpenSans';
  src: local('Open Sans Light Italic'), local('OpenSans-LightItalic'),
  url('./assets/fonts/open-sans/OpenSans-LightItalic.ttf') format('ttf'),
  url('./assets/fonts/open-sans/OpenSans-LightItalic.ttf') format('ttf');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'OpenSans';
  src: local('Open Sans SemiBold'), local('OpenSans-SemiBold'),
  url('./assets/fonts/open-sans/OpenSans-SemiBold.ttf') format('ttf'),
  url('./assets/fonts/open-sans/OpenSans-SemiBold.ttf') format('ttf');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'OpenSans';
  src: local('Open Sans Italic'), local('OpenSans-Italic'),
  url('./assets/fonts/open-sans/OpenSans-Italic.ttf') format('ttf'),
  url('./assets/fonts/open-sans/OpenSans-Italic.ttf') format('ttf');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'OpenSans';
  src: local('Open Sans ExtraBold Italic'), local('OpenSans-ExtraBoldItalic'),
  url('./assets/fonts/open-sans/OpenSans-ExtraBoldItalic.ttf') format('ttf'),
  url('./assets/fonts/open-sans/OpenSans-ExtraBoldItalic.ttf') format('ttf');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'OpenSans';
  src: local('Open Sans SemiBold Italic'), local('OpenSans-SemiBoldItalic'),
  url('./assets/fonts/open-sans/OpenSans-SemiBoldItalic.ttf') format('ttf'),
  url('./assets/fonts/open-sans/OpenSans-SemiBoldItalic.ttf') format('ttf');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'OpenSans';
  src: local('Open Sans ExtraBold'), local('OpenSans-ExtraBold'),
  url('./assets/fonts/open-sans/OpenSans-ExtraBold.ttf') format('ttf'),
  url('./assets/fonts/open-sans/OpenSans-ExtraBold.ttf') format('ttf');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: local('Open Sans Bold'), local('OpenSans-Bold'),
  url('./assets/fonts/open-sans/OpenSans-Bold.ttf') format('ttf'),
  url('./assets/fonts/open-sans/OpenSans-Bold.ttf') format('ttf');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: local('Open Sans Regular'), local('OpenSans-Regular'),
  url('./assets/fonts/open-sans/OpenSans-Regular.ttf') format('ttf'),
  url('./assets/fonts/open-sans/OpenSans-Regular.ttf') format('ttf');
  font-weight: normal;
  font-style: normal;
}

ion-header {
  ion-toolbar {
    ion-title {
    }

    ion-buttons {
      ion-button {
        --border-radius: 8px;
        --color: white;
        --padding-start: 14px;
        --padding-end: 14px;
        font-size: 14px;
        text-transform: none;

        img {
          margin-right: 10px;
        }

        &.btn-usuario {
          --background: var(--ion-color-primary-shade);
        }

        &.btn-sair {
          --background: var(--ion-color-danger);
        }
      }
    }
  }
}

ion-content {
  --background: var(--ion-color-light);

  div {
    &.resumo {
      background: var(--ion-color-warning-tint);

      div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0 auto;
        width: 410px;
      }
    }

    &.bg-verde {
      width: 100%;
      height: 122px;
      background: var(--ion-color-primary);
      position: absolute;
      z-index: -1;
    }

    &.wrapper {
      background: white;
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
      margin: 26px 20px;
      min-height: calc(100vh - 100px);
      border-radius: 0.75rem;

      .wrapper-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 106px;
        padding: 20px 30px;
        border-bottom: 2px solid var(--ion-color-light-shade);
        margin: 0 20px;

        h2 {
          margin-bottom: revert;
          color: var(--ion-color-medium-shade);
          flex-grow: 1;
        }

        ion-button {
          &.btn-success, &.btn-cancelar {
            height: 51px;
            font-size: 14px;
            font-weight: 700;
            --padding-top: 26px;
            --padding-bottom: 26px;
            --padding-start: 26px;
            --padding-end: 26px;
            text-transform: none;
          }
        }
      }

      .wrapper-body {
        padding: 0 24px;

        display: flex;
        background-color: var(--ion-color-light-tint);
        color: #444;

        div:last-child {
          border: none;
        }

        .left-content, .right-content, .modal-content {
          border-right: 2px solid var(--ion-color-light-shade);
          width: 100%;
          padding: 0 20px;

          ion-list {
            ion-list-header {
              font-size: 18px;
              font-weight: 600;
              line-height: 25px;
              letter-spacing: 0.0015em;
            }

            ion-item {
              margin-bottom: 0.5rem;
              --padding-start: 0px;
              --padding-end: 0px;
              --inner-padding-start: 0px;
              --inner-padding-end: 0px;

              .descricao {
                margin-top: 0.5rem;
                width: 100%;
                display: flex;
                justify-content: flex-end;

                span {
                  font-size: 12px;
                  color: #999999;
                }
              }

              ion-label {
                margin-bottom: 0.5rem;
              }

              ion-input, ion-select {
                border-radius: 3px;
                border: 1px solid var(--ion-color-medium-tint);
                background: var(--ion-color-light) !important;
                padding-right: 30px;
                font-size: 0.9em;
                --placeholder-color: var(--ion-color-medium);
                --color: var(--ion-color-medium);
                --padding-start: 14px;
                min-height: 44px;
              }

              ion-checkbox {
                width: 20px;
                height: 20px;
                margin: 0 0.5rem 0 0;
              }
            }

            &.lista {
              ion-item {
                height: 72px;
                margin-top: 0.5rem;
                border-radius: 5px;
                border: 1px solid var(--ion-color-light-shade);

                &.admin {
                  border-left: 6px solid var(--ion-color-warning);
                }

                &.gestor {
                  border-left: 6px solid var(--ion-color-danger);
                }

                ion-label {
                  height: 100%;
                  padding-left: 1rem;;
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                }
              }
            }
          }
        }
      }

      .wrapper-secao {
        padding: 0 24px;
        margin-top: 20px;
        display: grid;
        grid-gap: 20px;
        grid-template-columns: 2fr 2fr 2fr 2fr 2fr;

        div {
          &.secao {
            padding: 20px;
            background: white;
            border-radius: 1rem;
            box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
            text-align: center;

            img {
              max-width: 80px;
            }

            p {
              &.nome {
                font-size: 1.5rem;
                font-weight: 600;
                margin: 40px 0 5px 0;
                color: var(--ion-color-dark-tint);
              }
            }

            ion-list {
              ion-item {
                --padding-start: 0;
                --padding-end: 0;

                p {
                  &.ativo {
                    color: var(--ion-color-primary);
                    padding-left: 20px;

                    &:before {
                      content: '';
                      background: var(--ion-color-primary);
                      width: 10px;
                      height: 10px;
                      border-radius: 40px;
                      position: absolute;
                      left: 0;
                      top: 0;
                      margin-top: 22px;
                    }
                  }

                  &.inativo {
                    color: var(--ion-color-danger);
                    padding-left: 20px;

                    &:before {
                      content: '';
                      background: var(--ion-color-danger);
                      width: 10px;
                      height: 10px;
                      border-radius: 40px;
                      position: absolute;
                      left: 0;
                      top: 0;
                      margin-top: 22px;
                    }
                  }

                  &:first-child {
                    font-weight: 600;
                  }

                  &:last-child {
                    color: var(--ion-color-medium-tint);
                  }
                }
              }
            }
          }
        }
      }

      .wrapper-footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 100px;
        padding: 20px 30px;
        border-top: 2px solid #E4EAEF;
        margin: 0 20px;

        ion-button {
          width: 100%;
          margin-right: 2rem;

          &:last-child {
            margin: 0;
          }

          &.btn-success, &.btn-cancelar {
            height: 100%;
            font-size: 16px;
            font-weight: 600;
            --box-shadow: none;
            --padding-top: 26px;
            --padding-bottom: 26px;
            --padding-start: 26px;
            --padding-end: 26px;
            text-transform: none;
          }
        }
      }

      .aplicativo {
        padding: 20px;
        margin-bottom: 40px;
        background: white;
        border-radius: 6px;
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
        position: relative;
        cursor: pointer;

        img {
          max-width: 80px;
          box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
          position: absolute;
          left: 50%;
          margin-left: -40px;
          margin-top: -60px;
          border-radius: 1rem;
        }
      }

      .versoes {
        p {
          &.nome {
            font-weight: 600;
            margin: 40px 0 5px 0;
          }
        }

        ion-list {

          ion-item {
            --inner-padding-end: 0;
            --padding-start: 0;
            --padding-end: 0;

            p {
              width: 40%;

              &.ativo {
                color: var(--ion-color-primary);
                padding-left: 20px;
                position: relative;

                &:before {
                  content: '';
                  background: var(--ion-color-primary);
                  width: 10px;
                  height: 10px;
                  border-radius: 40px;
                  position: absolute;
                  margin-left: -13px;
                  top: 0;
                  margin-top: 10px;
                }
              }

              &.inativo {
                color: var(--ion-color-danger);
                padding-left: 20px;
                position: relative;

                &:before {
                  content: '';
                  background: var(--ion-color-danger);
                  width: 10px;
                  height: 10px;
                  border-radius: 40px;
                  position: absolute;
                  margin-left: -13px;
                  top: 0;
                  margin-top: 10px;
                }
              }

              &:first-child {
                font-weight: 600;
                border: 1px solid var(--ion-color-light);
                border-radius: 6px;
                text-align: center;
                width: 100%;
                padding: 6px 15px;
                margin-right: 10px;
              }

              &:last-child {
                margin: 0;
                color: var(--ion-color-medium-tint);
              }
            }
          }
        }
      }
    }
  }
}

.btn-success-outline {
  --border-color: var(--ion-color-primary);
  --color: var(--ion-color-primary);
}

.btn-success {
  --background: var(--ion-color-primary);
}

.btn-cancelar {
  --background: var(--ion-color-danger-tint);
  color: var(--ion-color-danger);
}

.hr-style {
  border: 0;
  height: 1px;
  background: var(--ion-color-light-shade);
}

.hr-menu {
  border: 0;
  height: 1px;
  background: var(--ion-color-light-shade);
}

/* Components */
app-empty-data,
app-error {
  width: 100%;
}

/* Modal */
.show-modal {
  .modal-wrapper {
    border-radius: 14px !important;

    .wrapper {
      margin: 0;

      .wrapper-header, .wrapper-footer {
        margin: 0;
      }

      .wrapper-body {
        .modal-content {
          padding: 0;
        }
      }

      .wrapper-footer {
        padding: 20px 24px;
      }
    }
  }
}

.modal-controle-versao,
.modal-usuarios-vinculados,
.modal-grupos {
  .wrapper {
    min-height: initial;
  }
}

.modal-controle-versao {
  --width: 590px;
  --height: 530px;
}

.modal-usuarios-vinculados {
  --height: 440px;
  --width: 550px;
}

.modal-grupos {
  --height: 600px;
  --width: 550px;
}

.modal-usuarios-vinculados,
.modal-grupos {
  .wrapper {
    .wrapper-header {
      height: auto !important;
      align-items: flex-start !important;
      flex-direction: column;
      padding: 8px 30px;

      h2 {
        font-size: 16px;
        color: var(--ion-color-medium-shade);
      }
    }
  }
}

.modal-calendario {
  --width: 400px;
  --height: 400px;
}

.loading-integrador {
  .loading-wrapper {
    display: block;
    text-align: center;
    box-shadow: none;
    background: transparent none !important;

    ion-spinner {
      width: 100px;
      height: 100px;
      background-image: url("assets/img/loading.gif");
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;
      -webkit-animation: spin 1s ease-out infinite;
      -moz-animation: spin 1s ease-out infinite;
      animation: spin 1s ease-out infinite;
      border-radius: 100%;
    }

    .loading-content {
      font-size: 0.563rem;
      text-transform: uppercase;
      animation: blinker 2s linear infinite;
      color: var(--ion-color-primary);
      margin-left: unset;
      -webkit-margin-start: 0 !important;
      margin-inline-start: 0 !important;
    }
  }

  // Workaround para esconder o spinner nativo
  .spinner-dots {
    &.sc-ion-loading-md {
      color: transparent;
    }

    &.sc-ion-loading-ios {
      color: transparent;
    }
  }
}
